import './style.css'

const ErrorForm = ({ retry }) => {

    return <div className="error-div">
        <i className="fa fa-warning wobble animated"></i>
        <h1 className="flash animated error-message">Something went wrong !</h1>
        <button className="btn btn-primary retry-button" type="button" onClick={() => retry()}>
            Try again
        </button>
    </div>
}

export default ErrorForm