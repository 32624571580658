import moment from "moment"

export const handleUserBalancetData = (balances) => {

    if (balances) {
        return balances
            .map(balance => {
                return {
                    time: moment(balance.date).valueOf(),
                    value: balance.balance
                }
            })
    }
}