import { Field } from "react-final-form";
import { Form, FormGroup } from "react-bootstrap";
import DatePicker from 'react-datepicker'
import './styles/DatePicker.css'
import moment from "moment";

const FinalFormDatePicker = ({ name, label, options }) => {
    return <Field name={name}>
        {({ input, meta }) => {
            const metaError = (meta.error || meta.submitError) && meta.touched;

            return <FormGroup>
                <Form.Label>{label}</Form.Label>
                <DatePicker
                    onChange={(value) => input.onChange(moment(value).valueOf())}
                    selected={input.value}
                    dateFormat="dd/MM/YYY HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    showTimeSelect
                />

                {metaError && <p className="field-error-message">{meta.error || meta.submitError}</p>}
            </FormGroup>
        }}
    </Field>
}

export default FinalFormDatePicker