import { useEffect } from "react";
import LineChart from "../../../Components/lineChart"
import { userBalanceLineChartConfig, userTradesTableConfig, userBalanceStatisticsConfig, userPortfolioConfig } from "./utils/webServiceDataConfig"
import Table from "../../../Components/table";
import { Row, Col } from "react-bootstrap";
import BalancStatistics from "./component/BalanceStatistics";
import './styles.css'
import { withUserContext } from "../../../Hoc/withUserContext";
import { useMemo, useState } from "react";
import ApexChart from "../../../Components/apexChart";
import Filter from "./component/Filter";
import moment from "moment";
import { DashboardFilterContext } from "../../../Contexts/DashboardFilterContext";
import { getBrokersByType } from "../../../Common/Constants";

const DashBoard = ({ user }) => {

    const [filter, setFilter] = useState({
        accountType: user.accountType,
        brokers: getBrokersByType(user.accountType),
        startDate: moment().subtract(1, 'days').valueOf(),
        endDate: moment.now()
    })

    const dashboardFilterContextValue = useMemo(() => {
        return {
            filter: filter,
            setFilter: (value) => setFilter({ ...filter, ...value })
        }
    }, [filter])
    const balanceHistoryConfig = useMemo(() => userBalanceLineChartConfig(
        {
            username: user.username,
            startDate: filter.startDate,
            endDate: filter.endDate,
            asset: "USDT",
            broker: filter.brokers[0].value
        })
        , [user.username, filter])
    const portfolioConfig = useMemo(() => userPortfolioConfig({
        username: user.username,
        broker: filter.brokers[0].value
    }), [user.username, filter])
    const balanceStatisticsConfig = useMemo(() => userBalanceStatisticsConfig(
        {
            username: user.username,
            startDate: filter.startDate,
            endDate: filter.endDate,
            broker: filter.brokers[0].value,
            statistics: ["BALANCE_STATISTICS"]
        }
    ), [user.username, filter])
    const tradesConfig = useMemo(() => userTradesTableConfig(
        {
            username: user.username,
            openDate: filter.startDate,
            closeDate: filter.endDate,
            brokers: [filter.brokers[0].value]
        })
        , [user.username, filter])

    useEffect(() => {
        user.accountType !== filter.accountType &&
            setFilter({
                accountType: user.accountType,
                brokers: getBrokersByType(user.accountType),
                startDate: filter.startDate,
                endDate: filter.endDate
            })
    }, [user.accountType, filter])

    return <DashboardFilterContext.Provider value={dashboardFilterContextValue}>
        <Filter />
        <Row className="min-height-row margin-botton-row">
            <Col xs={6}>
                <LineChart webServiceDataConfig={balanceHistoryConfig} />
            </Col>
            <Col xs={3}>
                <ApexChart webServiceDataConfig={portfolioConfig} />
            </Col>
            <Col xs={3}>
                <BalancStatistics webServiceDataConfig={balanceStatisticsConfig} />
            </Col>
        </Row>
        <Row>
            <Col >
                <Table webServiceDataConfig={tradesConfig} />
            </Col>
        </Row>
    </DashboardFilterContext.Provider>
}

export default withUserContext(DashBoard)