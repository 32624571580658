import React from 'react'
import { withWebServiceData } from '../../../../Hoc/withWebServiceData'
import './styles/BalanceStatistics.css'
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import clsx from 'clsx';
import { formatNumber } from '../../../../Common/Utils';
import './styles.css'

const BalancStatistics = ({ data: { balanceStatistic } }) => {
    return <div className='balance-statistics-div'>
        <table className="balance-statistics">
            <thead>
                <tr>
                    <th>
                        Statistic
                    </th>
                    <th className='text-align-end'>
                        {<span className={clsx(balanceStatistic.amountVariation < 0 && 'red', balanceStatistic.amountVariation > 0 && 'green', balanceStatistic.amountVariation === 0 && 'grey')}>
                        {balanceStatistic.amountVariation && balanceStatistic.amountVariation >= 0 && '+'} {(formatNumber(balanceStatistic.percentVariation * 100))} %
                            </span>}
                        {balanceStatistic.amountVariation < 0 && <AiFillCaretDown className='red' />}
                        {balanceStatistic.amountVariation > 0 && <AiFillCaretUp className='green' />}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Variation</td>
                    <td className='text-align-end'>{balanceStatistic.amountVariation && balanceStatistic.amountVariation >= 0 && '+'} {formatNumber(balanceStatistic.amountVariation)} USDT</td>
                </tr>
                <tr>
                    <td>Open</td>
                    <td className='text-align-end'>{formatNumber(balanceStatistic.open)} USDT</td>
                </tr>
                <tr>
                    <td>Close</td>
                    <td className='text-align-end'>{formatNumber(balanceStatistic.close)} USDT</td>
                </tr>
                <tr>
                    <td>High</td>
                    <td className='text-align-end'>{formatNumber(balanceStatistic.high)} USDT</td>
                </tr>
                <tr>
                    <td>Low</td>
                    <td className='text-align-end'>{formatNumber(balanceStatistic.low)} USDT</td>
                </tr>
            </tbody>
        </table>
    </div>
}

export default withWebServiceData(BalancStatistics)