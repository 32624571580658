import React, { useEffect, useState, useRef } from "react"
import { Spinner } from "react-bootstrap"
import { withErrorBoundry } from "./withErrorBoundry"
import { VscDebugDisconnect } from "react-icons/vsc";

export const withWebServiceData = WrappedComponent => {
    return withErrorBoundry((props) => {
        const { webServiceDataConfig, triggerError } = props
        const { webServiceCall, webServiceDataHandler, title } = webServiceDataConfig

        const [loading, setLoading] = useState(true)
        const [data, setData] = useState()
        const [error, setError] = useState()

        // To synchronize api calls: only the last api execution is considered
        let throttling = useRef(0)

        useEffect(() => {
            if (webServiceCall) {
                throttling.current = throttling.current + 1;
                let count = throttling.current
                setData(null)
                setLoading(true)
                webServiceCall()
                    .then(res => {
                        if (count === throttling.current) {
                            setLoading(false)
                            setData(webServiceDataHandler ? webServiceDataHandler(res.data) : res.data)
                        }
                    })
                    .catch(error => {
                        if (count === throttling.current) {
                            setLoading(false)
                            setError(error.response.data.error)
                        }
                    })
            } else {
                setLoading(false)
            }
        }, [webServiceCall, webServiceDataHandler, triggerError])

        return <>
        <div className="card-title">{title}</div>
            {
                loading ?
                    <div className="card-content"><Spinner animation="border" /></div> :
                    data == null || (Array.isArray(data) && data.length === 0) ?
                        error === "INVALID_TOKEN" ? <div className="card-content"><VscDebugDisconnect size={25} className="logo"/>Broker disconnected</div> : <div className="card-content">No data</div>
                        : <WrappedComponent data={data} {...props} />
            }
        </>
    })
}