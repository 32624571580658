import { formatNumber } from "../../Common/Utils"

export const handleUserPortfolioData = (brokerAccount) => {
    if (brokerAccount) {

        const series = []
        const labels = []

        brokerAccount.portfolio
            .sort(function (a, b) {
                return b.value - a.value;
            })
            .slice(0,5)
            .forEach(balance => {
                series.push(balance.value)
                labels.push(balance.asset)
            })

        return {
            series: series,
            options: {
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: (seriesName) => formatNumber(seriesName) + " USDT"
                    },
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: labels,
                dataLabels: {
                    enabled: true
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    position: 'bottom',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }
                },
                title: {
                    align: 'center',
                    offsetY: -8,
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        margin: '10px',
                        color: 'rgba(244, 244, 245, .6)'
                    }
                },
                stroke: {
                    show: false
                }
            }
        };
    }
}