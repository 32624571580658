import { useTable, useSortBy, usePagination, useExpanded } from 'react-table'
import './style.css'
import Header from './header'
import { withWebServiceData } from '../../Hoc/withWebServiceData'
import Pagination from './pagination'
import { Fragment } from 'react'
import React from 'react'

const Table = ({ data }) => {
    const {
        getTableProps,
        headerGroups,
        getTableBodyProps,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        state: { pageIndex },
        prepareRow
    } = useTable({
        columns: data.columns,
        data: data.values,
        initialState: data.initialState
    }, useSortBy, useExpanded, usePagination)

    return <div className='card-table-content'>
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => {
                    return <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((header, index) => {
                            return <Header key={index} header={header} />
                        })}
                    </tr>
                })}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row)
                    return <Fragment key={index}>
                        <tr key={index} {...row.getRowProps()} className={row.original?.status?.toLowerCase()}>
                            {row.cells.map((cell, index) => {
                                let expandedProps = data.expandable ? {...row.getToggleRowExpandedProps(), title: null} : {};
                                return <td key={index} {...cell.getCellProps()} {...expandedProps}>
                                    {cell.render('Cell')}
                                </td>
                            })}
                        </tr>
                        {row.isExpanded && <data.Expanded row={row} />}
                    </Fragment>
                })}
            </tbody>
        </table>
        <Pagination
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageIndex={pageIndex}
            totalRowsCount={data.values.length} />
    </div>
}

export default React.memo(withWebServiceData(Table));