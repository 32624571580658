export const sidebarItems = [
    {
        title: 'Dashboard',
        path: 'dashboard',
        icon: 'fa-home'
    },
    {
        title: 'Broker',
        path: 'broker',
        icon: 'fa-university'
    },
    {
        title: 'Market',
        path: 'market',
        icon: 'fa-line-chart'
    }
]

export const getItemByPath = (fullpath) => {
    let path = fullpath.split('/')[1]
    let item = sidebarItems.filter(i => i.path === path)
    return item.length > 0 ? item[0] : null
}