import { Badge } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const handleUserTradesTableData = (trades) => {
    const data = {
        title: 'Trades',
        columns: [
            {
                Header: 'Base',
                accessor: 'userMarket.base'
            },
            {
                Header: 'Quote',
                accessor: 'userMarket.quote'
            },
            {
                Header: 'Position',
                accessor: 'position',
                Cell: ({ value }) => <Badge bg="success">{value}</Badge>
            },
            {
                Header: 'Entry date',
                accessor: 'entryOrder.date',
                Cell: ({ value }) => new Date(Date.parse(value)).toLocaleString('fr-FR').replace(',', '')
            },
            {
                Header: 'Exit date',
                accessor: 'exitOrder',
                Cell: ({ value }) => value && new Date(Date.parse(value.date)).toLocaleString('fr-FR').replace(',', '')
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => <Badge bg={'OPEN' === value.toUpperCase() ? "warning" : "success"}>{value}</Badge>
            }
        ],
        values: trades,
        expandable: true,
        initialState: {
            sortBy: [
                {
                    id: 'entryOrder.date',
                    desc: true
                }
            ],
            pageSize: 5,
        }
    }
    data.Expanded = ({ row }) => {
        return <tr className="expanded">
            <td colSpan={data.columns.length} className="expanded">
                <Container>
                    <Row>
                        {row.original.entryOrder && <Col className="sub-order" xs="auto">
                            <p className="title">Entry Order</p>
                            <p>Quantity  : {row.original.entryOrder.subOrders[0].quantity}</p>
                            <p>Price     : {row.original.entryOrder.subOrders[0].price}</p>
                            <p>Commission: {row.original.entryOrder.subOrders[0].commission + ' ' + row.original.entryOrder.subOrders[0].commissionName}</p>
                        </Col>}
                        {row.original.exitOrder && <Col className="sub-order" xs="auto">
                            <p className="title">Exit Order</p>
                            <p>Quantity  : {row.original.exitOrder.subOrders[0].quantity}</p>
                            <p>Price     : {row.original.exitOrder.subOrders[0].price}</p>
                            <p>Commission: {row.original.exitOrder.subOrders[0].commission + ' ' + row.original.exitOrder.subOrders[0].commissionName}</p>
                        </Col>}
                    </Row>
                </Container>
            </td>
        </tr>
    }
    return data
}