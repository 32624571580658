import { formatNumber } from "../../Common/Utils"
import Crypto from "../../Components/crypto";


export const handleUserAssetTable = (assets) => {
    const data = {
        title: 'Assets',
        columns: [
            {
                Header: 'Asset',
                accessor: 'asset',
                Cell: ({ value }) => <Crypto asset={value.toLowerCase()}/>
            },
            {
                Header: 'Amount',
                accessor: 'free',
                Cell: ({ value }) => formatNumber(value)
            },
            {
                Header: 'National Value',
                accessor: 'value',
                Cell: ({ value }) => formatNumber(value) + " USDT"
            }
        ],
        values: assets.portfolio,
        initialState: {
            sortBy: [
                {
                    id: 'value',
                    desc: true
                }
            ],
            pageSize: 5,
        }
    }
    return data
}