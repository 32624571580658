import { useMemo, useState, useEffect, useCallback } from "react"
import { withUserContext } from "../../../Hoc/withUserContext"
import { userBrokerAccountTableConfig } from "./utils/webServiceDataConfig"
import Table from "../../../Components/table"
import { Col, Row, Button } from "react-bootstrap"
import Select from "../../../Components/select"
import { getBrokersByType } from "../../../Common/Constants"
import { AiOutlineTransaction } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { FaPiggyBank } from "react-icons/fa";
import './index.css'
import Modal from './components/Modal'
import { setUserAsset } from "../../../Service/spadiel/UserService"
import toast from 'react-hot-toast';
import { format } from 'react-string-format';

const BrokerPage = ({ user }) => {

    const [brokers, setBrokers] = useState(getBrokersByType(user.accountType))
    const [selectedBroker, selectBroker] = useState(getBrokersByType(user.accountType)[0])
    const [modalConfig, setModalConfig] = useState(null)

    const generateModalConfig = useCallback((header, type) => {
        return {
            header: header,
            onSubmit: (asset, amount) => {
                const id = toast.loading(format("Processing {0}...", type))
                let criteria = {
                    username: user.username,
                    asset: asset.value,
                    amount: type === 'deposit' ? amount : -amount,
                    broker: selectedBroker.value
                }
                setUserAsset(criteria)
                    .then(res => {
                        toast.success(format("Successful {0} of {1} {2}", type, amount, criteria.asset), { id })
                        setModalConfig(null)
                    })
                    .catch(error => toast.error(error.response.data.message, { id }))
            },
            onHide: () => setModalConfig(null)
        }
    }, [user.username, selectedBroker.value])
    const userAssetsConfig = useMemo(() => {
        return !modalConfig ?
            userBrokerAccountTableConfig({
                username: user.username,
                broker: selectedBroker.value
            }) : {}
    }, [user.username, selectedBroker, modalConfig])

    useEffect(() => {
        setBrokers(getBrokersByType(user.accountType))
        selectBroker(getBrokersByType(user.accountType)[0])
    }, [user.accountType])

    return <>
        <Modal config={modalConfig} />
        <Row className="margin-botton-row row-justify-end portfolio-row">
            <Col xs="auto">
                <Select options={brokers} onChange={selectedBroker} value={selectedBroker} />
            </Col>
            <Col xs="auto">
                <Button variant="danger" disabled={'LIVE' === user.accountType} onClick={() => setModalConfig(generateModalConfig('Withdraw funds', 'withdraw'))}><GiReceiveMoney /> Withdraw</Button>{' '}
            </Col>
            <Col xs="auto">
                <Button variant="warning" disabled={'LIVE' === user.accountType}><AiOutlineTransaction /> Transfer</Button>{' '}
            </Col>
            <Col xs="auto">
                <Button variant="success" disabled={'LIVE' === user.accountType} onClick={() => setModalConfig(generateModalConfig('Deposit funds', 'deposit'))}><FaPiggyBank /> Deposit</Button>{' '}
            </Col>
        </Row>
        <Row>
            <Col >
                <Table webServiceDataConfig={userAssetsConfig} />
            </Col>
        </Row>
    </>
}

export default withUserContext(BrokerPage)