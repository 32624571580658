import Select from 'react-select'

const CustomSelect = ({ options, value, onChange, isMulti, width, isDisabled }) => {
    return <Select
        options={options}
        isDisabled={isDisabled}
        className="basic-multi-select"
        classNamePrefix="select"
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        styles={{
            control: base => ({ ...base, width: width, boxShadow: 'none' }),
            menu: provided => ({ ...provided, zIndex: 3 })
        }}
    />
}

export default CustomSelect