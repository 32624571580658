import clsx from "clsx"
import { useState, useRef, useEffect } from "react"
import { logout } from "../../Service/authserver/AuthService"
import './styles.css'
import SwitchSelector from "react-switch-selector";
import { ACCOUNT_TYPES } from '../../Common/Constants'
import { withUserContext } from "../../Hoc/withUserContext";
import { MdOutlineModeEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { GrChannel } from "react-icons/gr";
import { AiOutlineMenu } from "react-icons/ai";
import { sidebarItems } from "../../Pages/home/utils/constants";
import { Link } from "react-router-dom";

const NavBar = ({ user }) => {

    const refUser = useRef()
    const refSideBar = useRef()
    const [open, setOpen] = useState(false)
    const [sidebarOpened, setSidebarOpen] = useState(false)

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (open && refUser.current && !refUser.current.contains(e.target)) {
                setOpen(false)
            }
            if (sidebarOpened && refSideBar.current && !refSideBar.current.contains(e.target)) {
                setSidebarOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open, sidebarOpened])

    return <div className='navbar'>
        <div className={clsx("sidebar-menu", sidebarOpened && "open")} onClick={() => setSidebarOpen(!sidebarOpened)} ref={refSideBar}>
            <AiOutlineMenu className="sidebar-logo" />
            <div className={clsx("menu", !sidebarOpened && "close")}>
                {sidebarItems.map((item, index) => (
                    <Link key={index} to={item.path}>
                        <div className="element">
                            <i className={"fa item-icon " + item.icon}></i>
                            <div>{item.title}</div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        <div className="account-switch">
            <SwitchSelector
                fontSize={15}
                selectedFontColor={"#171831"}
                onChange={(value) => user.setUser({ accountType: value })}
                options={ACCOUNT_TYPES}
                forcedSelectedIndex={ACCOUNT_TYPES.findIndex(({ value }) => value === user.accountType)}
                backgroundColor={"rgba(0, 0, 0, .24)"}
                fontColor={"#f5f6fa"}
            />
        </div>
        <div className="user" ref={refUser} onClick={() => setOpen(!open)}>
            <i className={clsx("menu-logo fa fa-user-circle", open && "open")}></i>
            <div className={clsx("menu", !open && "close")}>
                <Link to={'profile'}>
                    <div className="element">
                        <MdOutlineModeEdit /> Profile
                    </div>
                </Link>
                <Link to={'settings'}>
                    <div className="element">
                        <IoSettingsOutline /> Settings
                    </div>
                </Link>
                <Link to={'preferences'}>
                    <div className="element">
                        <GrChannel /> Preferences
                    </div>
                </Link>
                <div className="signout" onClick={logout}>
                    <i className="fa fa-sign-out" aria-hidden="true" />
                    <div>Sign out</div>
                </div>
            </div>
        </div>
    </div>
}

export default withUserContext(NavBar)