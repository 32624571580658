
import Crypto from "../Components/crypto";

export const ROLE_PREFIX = 'ROLE_'
export const ROLE_SEPARATOR = '_'
export const ACCOUNT_TYPES = [
    {
        label: "Live",
        value: "LIVE",
        selectedBackgroundColor: "#1de6a2"
    },
    {
        label: "Virtual",
        value: "VIRTUAL",
        selectedBackgroundColor: "#fecc69",
    }
];
export const brokers = {
    'LIVE': [{ value: 'BINANCE', label: 'Binance' }],
    'VIRTUAL': [{ value: 'V_BINANCE', label: 'Binance' }]
}
export const getBrokersByType = (type) => brokers[type]
export const eligibleAssets = [
    {
        value: 'USDT',
        label: <Crypto asset='USDT'/>
    },
    {
        value: 'BTC',
        label: <Crypto asset='BTC'/>
    },
    {
        value: 'ETH',
        label: <Crypto asset='ETH'/>
    },
    {
        value: 'BNB',
        label: <Crypto asset='BNB'/>
    },
    {
        value: 'ENJ',
        label: <Crypto asset='ENJ'/>
    }]