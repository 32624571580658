import instance from './Instance';
import StringBuilder from "string-builder";
import { Token } from '../../Common/Enums.ts';
import { getToken, removeToken, isAccessTokenValid } from '../../Common/JwtHelper';

export const signIn = () => {
    const authServerUrl = process.env.REACT_APP_AUTHSERVER_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientScopes = process.env.REACT_APP_CLIENT_SCOPES;

    const sb = new StringBuilder();
    sb.append(authServerUrl)
    sb.append("/auth?")
    sb.appendFormat("client_id={0}&", clientId)
    sb.append("response_type=code&")
    sb.appendFormat("scope={0}", clientScopes)

    window.location.replace(sb.toString())
}

export const exchangeCode = (code) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('redirect_uri', process.env.REACT_APP_HOST + '/authorize');
    params.append('client_id', process.env.REACT_APP_CLIENT_ID);
    params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);

    return instance.post("/token", params)
}

export const isLogged = () => {
    return isAccessTokenValid() || getToken(Token.Refresh);
}

export const refreshToken = (refreshToken) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    params.append('client_id', process.env.REACT_APP_CLIENT_ID);
    params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
    
    return instance.post("/token", params)
}

export const logout = () => {
    const authServerUrl = process.env.REACT_APP_AUTHSERVER_URL;
    const appHost = process.env.REACT_APP_HOST;

    revokeToken(Token.Refresh)

    removeToken(Token.Access)
    removeToken(Token.Refresh)

    const sb = new StringBuilder();
    sb.append(authServerUrl)
    sb.append("/logout?")
    sb.appendFormat("redirect={0}", appHost)

    window.location.replace(sb.toString())
}

export const revokeToken = (type) => {
    instance.post("/revoke", {}, {
        params: {
            token: getToken(type),
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET
        }
    })
}