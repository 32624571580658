import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import LoginPage from './Pages/public';
import HomePage from './Pages/home';
import Authorize from './Pages/authorize';
import { Toaster } from 'react-hot-toast';
import RegisterPage from "./Pages/public/register";

const toastOptions = {
  position: 'bottom-right',
  success: {
    style: {
      background: '#07bc0c',
      color: '#fff',
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#07bc0c',
    },
    duration: 4000,
  },
  error: {
    style: {
      background: '#e74c3c',
      color: '#fff',
    },
    iconTheme: {
      primary: '#fff',
      secondary: '#e74c3c',
    },
    duration: 4000,
  },
  loading: {
    style: {
    }
  }
}

function App() {
  return <>
    <Router>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/authorize' element={<Authorize />} />
        <Route path='/*' element={<HomePage />} />
      </Routes>
    </Router>
    <Toaster toastOptions={toastOptions} />
  </>
}

export default App;
