import { Container, Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap"
import { BiUserCircle, BiLock, BiMailSend, BiPhone, BiArrowBack } from "react-icons/bi";
import { MdCreate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Form as FinalForm, Field } from "react-final-form";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { createUser } from "../../../Service/spadiel/UserService";
import toast from 'react-hot-toast';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { useState } from "react";
import './styles.css'
import { signIn } from "../../../Service/authserver/AuthService";
import { IoMdLogIn } from "react-icons/io";

const RegisterPage = (props) => {

    const [isCreated, setIsCreated] = useState(false)

    const navigate = useNavigate();

    const Item = ({ name, title, Icon, placeholder, type }) => {
        return <Field name={name}>
            {({ input, meta }) => {
                const metaError = (meta.error || meta.submitError) && meta.touched;

                return <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label className="mb-2">{title}</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text><Icon /></InputGroup.Text>
                                <FormControl
                                    placeholder={placeholder}
                                    type={type}
                                    {...input}
                                    autoComplete="off"
                                />
                            </InputGroup>
                            {metaError && <p className="field-error-message">{meta.error || meta.submitError}</p>}
                        </Form.Group>
                    </Col>
                </Row>
            }}
        </Field>
    }

    const FinalFormPhone = ({ name, title, Icon }) => {
        return <Field name={name}>
            {({ input, meta }) => {
                const metaError = (meta.error || meta.submitError) && meta.touched;

                return <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label className="mb-2">{title}</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text><Icon /></InputGroup.Text>
                                <PhoneInput {...input} country='fr' className="form-control form-control-phone" inputClass="form-control-phone-input" />
                            </InputGroup>
                            {metaError && <p className="field-error-message">{meta.error || meta.submitError}</p>}
                        </Form.Group>
                    </Col>
                </Row>
            }}
        </Field>
    }

    const validate = (values) => {
        const errors = {}
        if (!values.username) {
            errors.username = "Required"
        }
        if (!values.password) {
            errors.password = "Required"
        }
        if (!values.password2) {
            errors.password2 = "Required"
        } else if (values.password2 !== values.password) {
            errors.password2 = "Passwords does not match"
        }
        if (!values.email) {
            errors.email = "Required"
        }
        if (!values.phone) {
            errors.phone = "Required"
        }
        return errors;
    }

    const onSubmit = (values) => {
        const id = toast.loading("Creating user...")
        createUser({
            username: values.username,
            password: values.password,
            email: values.email,
            phone: values.phone
        })
        .then(() => {
            toast.success("User sccessfully created", { id })
            setIsCreated(true)
        })
        .catch(error => toast.error(error.response.data.message, { id }))
    }

    return <Container>
        <Row className="register-row">
            <Col xs={5} className="register-col">
                {!isCreated ?
                    <FinalForm
                        onSubmit={onSubmit}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <>
                                <Item name="username" title="Username" Icon={BiUserCircle} placeholder="User123" type="text" />
                                <Item name="password" title="Password" Icon={BiLock} placeholder="*******" type="password" />
                                <Item name="password2" title="Password verification" Icon={BiLock} placeholder="*******" type="password" />
                                <Item name="email" title="Email" Icon={BiMailSend} placeholder="user@domaine.com" type="text" />
                                <FinalFormPhone name="phone" title="Phone" Icon={BiPhone} />

                                <Row>
                                    <Col>
                                        <Button variant="outline-danger" onClick={() => navigate("/home")}>
                                            <BiArrowBack />Back to home
                                        </Button>
                                    </Col>
                                    <Col className="register-submit-button" >
                                        <Button variant="outline-success" onClick={handleSubmit}>
                                            Register<MdCreate />
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )} /> :
                    <>
                        <Row><IoCheckmarkCircleSharp className="created-logo" /></Row>
                        <Row><h3 className="created-text">Account successfully created</h3></Row>
                        <Row>
                            <Col>
                                <Button variant="outline-danger" onClick={() => navigate("/home")}>
                                    <BiArrowBack />Back to home
                                </Button>
                            </Col>
                            <Col className="register-submit-button" >
                                <Button variant="outline-success" onClick={signIn}>
                                    Login<IoMdLogIn />
                                </Button>
                            </Col>
                        </Row>
                    </>
                }
            </Col>
        </Row>

    </Container>
}

export default RegisterPage