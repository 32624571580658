import React, { useEffect, useMemo, useState } from 'react'
import './style.css'
import { isLogged, logout } from '../../Service/authserver/AuthService'
import { Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from '../../Contexts/UserContext'
import { getUserDetails } from '../../Service/spadiel/UserService';
import { getToken } from '../../Common/JwtHelper';
import { Token } from '../../Common/Enums.ts';
import jwt_decode from "jwt-decode";
import Spinner from '../../Components/spinner';
import NavBar from '../../Components/navBar';
import DashBoard from './dashboard';
import BrokerPage from './broker';
import MarketPage from './market';
import { Container } from 'react-bootstrap';
import SettingsPage from './settings';

const Home = React.memo((props) => {

    const [user, setUser] = useState();

    const userContextValue = useMemo(() => {
        return { ...user, setUser: (value) => setUser({ ...user, ...value }) }
    }, [user])

    useEffect(() => {
        getUserDetails(jwt_decode(getToken(Token.Access)).preferred_username)
            .then(res => {
                setUser({
                    username: res.data.preferred_username,
                    role: 'Passenger',
                    accountType: 'LIVE',
                })
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return user ?
        <UserContext.Provider value={userContextValue}>
            <NavBar />
            <div className='main'>
                <div className='content'>
                    <Container fluid="xs">
                        {props.children}
                    </Container>
                </div>
            </div>
        </UserContext.Provider> :
        <Spinner />
})

const HomePage = () => {
    return isLogged() ?
        <Home>
            <Routes>
                <Route path='/dashboard' element={<DashBoard />} />
                <Route path='/broker' element={<BrokerPage />}/>
                <Route path='/market' element={<MarketPage/>} />
                <Route path='/settings' element={<SettingsPage />} />
                <Route path='/*' element={<></>} />
            </Routes>
        </Home> :
        <Navigate to="/" />
}

export default HomePage;