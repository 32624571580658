import ReactApexChart from "react-apexcharts";
import { withWebServiceData } from "../../Hoc/withWebServiceData";
import './styles.css'

const ApexChart = withWebServiceData(({ data }) => {
    return <div className="apex-chart">
        <ReactApexChart options={data.options} series={data.series} type="donut" width={'100%'} height={'100%'} />
    </div>
})

export default ApexChart