import { useState } from "react"
import { Modal } from "react-bootstrap"
import { Col, Row, Button, InputGroup, FormControl } from "react-bootstrap"
import { eligibleAssets } from "../../../../Common/Constants"
import Select from "../../../../Components/select"

const AssetModal = ({ config }) => {
    const { onHide, onSubmit, header } = config ? config : {}

    const [asset, selectAsset] = useState(eligibleAssets[0])
    const [amount, setAmount] = useState(null)

    return <Modal show={config} onHide={() => {
        onHide()
        setAmount(null)
    }} size="lg" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {header}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs="auto">
                    <Select options={eligibleAssets} value={asset} onChange={selectAsset} width={150} />
                </Col>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Amount</InputGroup.Text>
                        <FormControl placeholder="123.45" onChange={(e) => setAmount(e.target.value)} value={amount} />
                    </InputGroup>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={() => {
                onHide()
                setAmount(null)
            }}>Cancel</Button>
            <Button variant="success" onClick={() => {
                onSubmit(asset, amount)
                setAmount(null)
            }}>Validate</Button>
        </Modal.Footer>
    </Modal>
}

export default AssetModal