import Select from 'react-select'
import { Field } from "react-final-form";
import { Form, FormGroup } from "react-bootstrap";

const FinalFormSelect = ({ name, label, options }) => {
    return <Field name={name}>
        {({ input, meta }) => {
            const metaError = (meta.error || meta.submitError) && meta.touched;

            return <FormGroup>
                <Form.Label>{label}</Form.Label>
                <Select
                    {...input}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isMulti
                    styles={{
                        control: base => ({ ...base, border: 0, boxShadow: 'none' }),
                        menu: provided => ({ ...provided, zIndex: 3 })
                    }}
                />

                {metaError && <p className="field-error-message">{meta.error || meta.submitError}</p>}
            </FormGroup>
        }}
    </Field>
}

export default FinalFormSelect