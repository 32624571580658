import React from "react"
import ErrorForm from "../Components/errorForm"

// Class is used instead of functional component because the hook that catches 
// errors does not exists yet, and we cannot use try catch as it only catches imperative
// instruction errors and not error inside children renders
export const withErrorBoundry = (WrappedComponent) => {
    class EnhancedComponent extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                hasError: false
            }

            this.triggerError = this.triggerError.bind(this);
            this.retry = this.retry.bind(this);
        }

        static getDerivedStateFromError(error) {
            // Update state
            return { hasError: true };
        }

        componentDidCatch(error, errorInfo) {
            // This is used for logging the error (we can call a webservice too)
            console.log("Error boundary has catched " + error)
        }

        componentDidUpdate(prevProps) {
            // for with web service call => if webServiceDataConfig changes then we retry
            prevProps.webServiceDataConfig !== this.props.webServiceDataConfig && this.setState({hasError: false})
        }

        triggerError(error) {
            console.log("Error throwed manually " + error)
            this.setState({
                hasError: true
            });
        }

        retry() {
            this.setState({
                hasError: false
            });
        }

        render() {
            return <div className="card">
                {this.state.hasError ?
                    <ErrorForm retry={this.retry} {...this.props} /> : <WrappedComponent triggerError={this.triggerError} {...this.props} />}
            </div>
        }
    }
    return EnhancedComponent
}