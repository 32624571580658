import { useEffect, useState } from "react";
import Spinner from '../../Components/spinner'
import { Navigate } from "react-router-dom";
import { setToken } from "../../Common/JwtHelper";
import { Token } from "../../Common/Enums.ts";
import { exchangeCode } from '../../Service/authserver/AuthService'

const Authorize = () => {

    const [loginSuccess, setLoginSuccess] = useState(false)

    useEffect(() => {
        if (!loginSuccess) {
            const code = new URLSearchParams(window.location.search).get('code')

            exchangeCode(code)
                .then(res => {
                    setToken(Token.Access, res.data.access_token)
                    setToken(Token.Refresh, res.data.refresh_token)

                    setLoginSuccess(true)
                })
                .catch(error => console.log(error))
        }
    }, [loginSuccess]);
    
    return loginSuccess ? <Navigate to="/dashboard" /> : <Spinner />
}

export default Authorize;