import { logout } from "../authserver/AuthService";
import axios from 'axios'
import { isAccessTokenValid, getToken, setToken } from "../../Common/JwtHelper";
import { Token } from '../../Common/Enums.ts'
import { refreshToken } from "../authserver/AuthService";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_GATEWAY_HOST + "/" + process.env.REACT_APP_SPADIEL_SERVICE_ID
});

// request interceptor
instance.interceptors.request.use(
    async (config) => {
        if (isAccessTokenValid()) {
            config.headers["Authorization"] = "Bearer " + getToken(Token.Access);
        } else if (getToken(Token.Refresh)) {
            await refreshToken(getToken(Token.Refresh))
                .then(response => setToken(Token.Access, response.data.access_token))
                .catch(() => logout())
            
            config.headers["Authorization"] = "Bearer " + getToken(Token.Access);
        } else {
            logout()
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;