import { Row, Col, Button } from "react-bootstrap"
import { getBrokersByType } from "../../../Common/Constants"
import Table from "../../../Components/table"
import { withUserContext } from "../../../Hoc/withUserContext"
import { userMarketsTableConfig } from "./utils/webServiceDataConfig"
import Select from "../../../Components/select"
import './styles.css'
import { useEffect, useMemo, useState } from "react"

const Market = ({ user }) => {

    const [filter, setFilter] = useState(null)
    const [countRefresh, setCountRefresh] = useState(0)
    const [brokers, setBrokers] = useState(null)

    const userMarketsConfig = useMemo(() => {
        return filter ? userMarketsTableConfig({
            username: filter.username,
            brokers: filter.brokers.map(b => b.value),
            incrementRefresh: () => setCountRefresh(countRefresh+1)
        }) : {}
    }, [filter, countRefresh])

    useEffect(() => {
        setFilter({ username: user.username, brokers: getBrokersByType(user.accountType) })
        setBrokers(getBrokersByType(user.accountType))
    }, [user.accountType, user.username, setFilter])

    return <div className="market">
        <Row xs="auto" className="filer-row margin-botton-row">
            <Col>
                <Select isMulti options={getBrokersByType(user.accountType)} onChange={setBrokers} value={brokers} />
            </Col>
            <Col>
                <Button variant="success" onClick={() => setFilter({
                    username: user.username,
                    brokers: brokers
                })}>
                    <i className="fa fa-refresh" aria-hidden="true"><span>Refresh</span></i>
                </Button>
            </Col>
        </Row>
        <Row >
            <Col >
                <Table webServiceDataConfig={userMarketsConfig} />
            </Col>
        </Row>
    </div>
}

export default withUserContext(Market)