import instance from './Instance';
import axios from 'axios'
import { format } from 'react-string-format';
import jwt_decode from "jwt-decode";
import { getToken } from '../../Common/JwtHelper';
import { Token } from '../../Common/Enums.ts';

const publicInstance = axios.create({
    baseURL: process.env.REACT_APP_API_GATEWAY_HOST + "/" + process.env.REACT_APP_SPADIEL_SERVICE_ID
});

export const getUserDetails = (username) => {
    return instance.get("/v1/user", {
        params: {
            username: username
        }
    })
}

export const getUserBalances = (criteria) => {
    return instance.post("/v1/user/balance", criteria)
}

export const getTrades = (criteria) => {
    return instance.post("/v1/trade", criteria)
}

export const getBalanceStatistics = (criteria) => {
    return instance.post("/v1/user/statistics", criteria)
}

export const getUserBrokerAccount = (criteria) => {
    return instance.post("/v1/user/broker/account", criteria)
}

export const getUserMarkets = (criteria) => {
    return instance.post("/v1/user/market/search", criteria)
}


export const setUserAsset = (criteria) => {
    return instance.post("/v1/user/virtual/asset", criteria)
}

export const createUser = (criteria) => {
    return publicInstance.post("/v1/user/create", criteria)
}

export const getBrokerCredentials = (criteria) => {
    return instance.post("/v1/user/broker/credentials", criteria)
}

export const updateBrokerCredentials = (criteria) => {
    return instance.put("/v1/user/broker/credentials", criteria)
}

export const closeUserMarket = (marketId) => {
    return instance.delete(format("/v1/user/market?username={0}&marketId={1}", jwt_decode(getToken(Token.Access))?.sub, marketId))
}

export const openUserMarket = (marketId) => {
    return instance.post(format("/v1/user/market?username={0}&marketId={1}", jwt_decode(getToken(Token.Access))?.sub, marketId))
}