import React, { useEffect } from 'react';
import { createChart } from 'lightweight-charts';
import './style.css'
import { withWebServiceData } from '../../Hoc/withWebServiceData';
import moment from 'moment';
import { formatNumber } from '../../Common/Utils';

const LineChart = ({ data }) => {
    const ref = React.useRef();

    useEffect(() => {
        if (ref.current) {
            // we must cleat the ref content before inserting new content
            // otherwise new content will be appended to the old one
            ref.current.innerHTML = ''
            ref.current.style='width: 100%;'
        }

        var chart = createChart(ref.current, {
            height: 300,
            localization: {
                // label x axis formatter
                timeFormatter: function (time) {
                    return moment(time).format("DD/MM/YYYY HH:mm:ss");
                },
            },
            layout: {
                textColor: 'rgba(244, 244, 245, .6)',
                backgroundColor: 'rgba(0, 0, 0, 0)'
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.2,
                    bottom: 0.2,
                },
                borderVisible: true,
            },
            timeScale: {
                // x axis formatter
                tickMarkFormatter: (time) => {
                    let date = moment(time)
                    if (date.hour() === 0 && date.minute() === 1) {
                        return date.format("DD/MMM")
                    } else {
                        return date.format("HH:mm:ss")
                    }
                }
            },
            grid: {
                horzLines: {
                    visible: false,
                },
                vertLines: {
                    visible: false
                },
            },
            crosshair: {
                horzLine: {
                    visible: true,
                    labelVisible: true
                },
                vertLine: {
                    visible: true,
                    labelVisible: true
                }
            },
        });

        // series
        var series = chart.addAreaSeries({
            topColor: data[data.length - 1].value - data[0].value >= 0 ? 'green' : 'red',
            bottomColor: 'rgba(0, 0, 0, 0)',
            lineColor: data[data.length - 1].value - data[0].value >= 0 ? 'green' : 'red',
            lineWidth: 2
        });
        series.setData(data);

        // Make Chart Responsive with screen resize
        new ResizeObserver(entries => {
            chart.applyOptions({ width: entries[0].contentRect.width });
            // to fit all div
            chart.timeScale().fitContent()
        }).observe(ref.current);

        // tooltip
        var toolTip = document.createElement('div');
        toolTip.className = 'linechart-tooltip';
        ref.current.appendChild(toolTip);

        function setLastBarText() {
            toolTip.innerHTML = moment(data[data.length - 1].time).format("DD-MM-YYYY HH:mm:ss") + " " + formatNumber(data[data.length - 1].value) + " USDT"
        }
        setLastBarText();

        chart.subscribeCrosshairMove(function (param) {
            if (param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0) {
                setLastBarText();
            } else {
                toolTip.innerHTML = moment(param.time).format("DD-MM-YYYY HH:mm:ss") + " " + formatNumber(param.seriesPrices.get(series)) + " USDT"
            }


        }, [])

    });

    return <div ref={ref} />
}

export default withWebServiceData(React.memo(LineChart))