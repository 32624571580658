import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './styles/Filter.css'
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { Form } from "react-final-form";
import Select from '../../../../Components/finalForm/Select';
import DatePicker from '../../../../Components/finalForm/DatePicker';
import moment from 'moment';
import { withUserContext } from '../../../../Hoc/withUserContext';
import { withDashBoardFilterContext } from '../../../../Hoc/withDashBoardFilterContext';
import { getBrokersByType } from '../../../../Common/Constants';

export const validate = (values) => {
    const errors = {};
    if (!(values.brokers?.length > 0)) {
        errors.brokers = "Required";
    }
    if (!values.startDate) {
        errors.startDate = "Required";
    }
    if (!values.endDate) {
        errors.endDate = "Required";
    }
    if (moment(values.startDate).isAfter(values.endDate)) {
        errors.startDate = "Interval incoherent";
        errors.endDate = "Interval incoherent";
    }
    return errors;
}

const Filter = ({ user, filter, setFilter }) => {
    return <Form
        initialValues={{
            brokers: filter.brokers,
            startDate: filter.startDate,
            endDate: filter.endDate
        }}
        onSubmit={(values) => setFilter({ ...values })}
        validate={validate}
        render={({ handleSubmit }) => (
            <Row xs="auto" className="filer-row margin-botton-row">
                <Col>
                    <Select name="brokers" label="Brokers" options={getBrokersByType(user.accountType)} />
                </Col>
                <Col>
                    <DatePicker name="startDate" label="From" />
                </Col>
                <Col>
                    <DatePicker name="endDate" label="To" />
                </Col>
                <Col>
                    <Button variant="success" onClick={handleSubmit}>
                        <i className="fa fa-refresh" aria-hidden="true"><span>Refresh</span></i>
                    </Button>
                </Col>
            </Row>
        )} />
}

export default React.memo(withDashBoardFilterContext(withUserContext(Filter)))