
import Icon from "react-crypto-icons";
import { Row, Col } from "react-bootstrap";

const Crypto = ({ asset }) => {
    return <Row>
        <Col xs="auto" className="no-padding-left">
            <Icon name={asset.toLowerCase()} size={25}></Icon>
        </Col>
        {asset.toUpperCase()}
    </Row>
}

export default Crypto