import clsx from "clsx";
import { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button, InputGroup, FormControl, Spinner } from "react-bootstrap"
import { Form as FinalForm, Field } from "react-final-form";
import { withUserContext } from "../../../Hoc/withUserContext";
import { getBrokerCredentials, updateBrokerCredentials } from "../../../Service/spadiel/UserService";
import toast from "react-hot-toast";

const SettingsPage = ({ user }) => {

    const [credentials, setCredentials] = useState(null)

    useEffect(() => {
        getBrokerCredentials({
            username: user.username,
            broker: 'BINANCE'
        }).then(res => {
            setCredentials(res.data ? res.data : {
                key: '',
                secret: ''
            })
        })
    }, [user.username])

    const Item = ({ name, title, placeholder }) => {
        return <Field name={name}>
            {({ input, meta }) => {
                const metaError = (meta.error || meta.submitError) && meta.touched;

                return <Form.Group>
                    <Form.Label>{title}</Form.Label>
                    <InputGroup>
                        <FormControl
                            placeholder={placeholder}
                            type="text"
                            {...input}
                            autoComplete="off"
                        />
                    </InputGroup>
                    {metaError && <p className="field-error-message">{meta.error || meta.submitError}</p>}
                </Form.Group>
            }}
        </Field>
    }

    const onSubmit = (values) => {
        const id = toast.loading("Updateing broker credentials...")
        updateBrokerCredentials({
            username: user.username,
            broker: 'BINANCE',
            key: values.apiKey,
            secret: values.apiSecret
        }).then(res => {
            toast.success("Broker credentials successfully updated", { id })
        }).catch(error => toast.error(error.response.data.message, { id }))
    }

    const validate = (values) => {
        const errors = {};
        if (!values.apiKey) {
            errors.apiKey = "Required";
        }
        if (!values.apiSecret) {
            errors.apiSecret = "Required";
        }
        return errors;
    }

    return <Container>

        <Row className={clsx("row-align-end", !credentials && "row-center")}>
            {!credentials ?
                <Spinner animation="border" /> :
                <FinalForm
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{
                        apiKey: credentials.key,
                        apiSecret: credentials.secret
                    }}
                    render={({ handleSubmit }) => (
                        <>
                            <Col>
                                <Item name="apiKey" title="Binance Api key" placeholder="Api key" />
                            </Col>
                            <Col>
                                <Item name="apiSecret" title="Binance Api secret" placeholder="Api secret" />
                            </Col>
                            <Col>
                                <Button variant="outline-success" onClick={handleSubmit}>
                                    Update
                                </Button>
                            </Col>
                        </>
                    )} />
            }
        </Row>
    </Container>
}

export default withUserContext(SettingsPage);