import { getUserBalances, getTrades, getBalanceStatistics, getUserBrokerAccount } from '../../../../Service/spadiel/UserService'
import { handleUserBalancetData } from '../../../../Service/webServiceDataHandler/userBalanceLineChart'
import { handleUserTradesTableData } from '../../../../Service/webServiceDataHandler/userOrdersTable'
import { handleUserPortfolioData } from '../../../../Service/webServiceDataHandler/userPortfolioChart'

export const userBalanceLineChartConfig = (criteria) => {
    return {
        title: "Balance History",
        webServiceCall: () => getUserBalances(criteria),
        webServiceDataHandler: handleUserBalancetData,
    }
}

export const userTradesTableConfig = (criteria) => {
    return {
        title: "Trades",
        webServiceCall: () => getTrades(criteria),
        webServiceDataHandler: handleUserTradesTableData,
    }
}

export const userBalanceStatisticsConfig = (criteria) => {
    return {
        title: "Balance Statistics",
        webServiceCall: () => criteria ? getBalanceStatistics(criteria) : null
    }
}

export const userPortfolioConfig = (criteria) => {
    return {
        title: "Portfolio top 5 assets",
        webServiceCall: () => getUserBrokerAccount(criteria),
        webServiceDataHandler: handleUserPortfolioData,
    }
}