import { Routes, Route, Navigate, Link } from "react-router-dom";
import { isLogged, signIn } from '../../Service/authserver/AuthService'
import { Container, Row, Col } from 'react-bootstrap';
import { MdOutlineCreate } from "react-icons/md";
import { IoMdLogIn } from "react-icons/io";
import RegisterPage from './register';
import './style.css'

const PublicRoutes = (props) => {
    return <Container fluid>
        <Row className="public-navbar">
            <Col>
                <div className="logo">Ognetra</div>
            </Col>
            <Col xs="auto" className="navbar-item">
                Home
            </Col>
            <Col xs="auto" className="navbar-item">
                Pricing
            </Col>
            <Col xs="auto" className="navbar-item">
                Contact
            </Col>
            <Col xs="auto" className="navbar-item">
                About
            </Col>
            <Col className="navbar-signin-login">
                <Link to={"register"}>
                    <button>
                        <MdOutlineCreate />
                        Register
                    </button>
                </Link>
                <button onClick={signIn}>
                    <IoMdLogIn />
                    Log in
                </button>
            </Col>
        </Row>
        <Row>
            {props.children}
        </Row>
    </Container>
}

const Public = () => {
    return isLogged() ?
        <Navigate to="/dashboard" /> :
        <PublicRoutes>
            <Routes>
                <Route path='/register' element={<RegisterPage />} />
            </Routes>
        </PublicRoutes>
}

export default Public