import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './style.css'

const Pagination = ({ pageIndex, pageOptions, gotoPage, canPreviousPage, canNextPage, previousPage, nextPage, totalRowsCount }) => {
    return <Container>
        <Row className='pagination'>
            <Col>
                <strong>{totalRowsCount + ' elements'}</strong>
            </Col>
            <Col xs="auto">
                <strong>{pageIndex + 1} / {pageOptions.length !== 0 ? pageOptions.length : 1}</strong>
            </Col>
            <Col xs="auto">
                <i className="fa fa-angle-double-left" onClick={() => gotoPage(0)} disabled={!canPreviousPage}></i>
                <i className="fa fa-angle-left" onClick={() => previousPage()} disabled={!canPreviousPage}></i>
                <i className="fa fa-angle-right" onClick={() => nextPage()} disabled={!canNextPage}></i>
                <i className="fa fa-angle-double-right" onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}></i>
            </Col>
        </Row>
    </Container>
}

export default Pagination