import { Badge, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { format } from "react-string-format";
import Crypto from "../../Components/crypto";
import { closeUserMarket, openUserMarket } from "../spadiel/UserService";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const handleUserMarketsTable = (markets, incrementRefresh) => {
    const data = {
        title: 'Markets',
        columns: [
            {
                Header: 'Base',
                accessor: 'base',
                Cell: ({ value }) => <Crypto asset={value.toLowerCase()} />
            },
            {
                Header: 'Quote',
                accessor: 'quote'
            },
            {
                Header: 'Broker',
                accessor: 'broker'
            },
            {
                Header: 'Position',
                accessor: 'position',
                Cell: ({ value }) => {
                    if (value.toUpperCase() === "SHORT") {
                        return <Badge bg={"success"}>{capitalize(value)}</Badge>
                    } else if (value.toUpperCase() === "LONG") {
                        return <Badge bg={"danger"}>{capitalize(value)}</Badge>
                    } else {
                        return <Badge bg={"secondary"}>{capitalize(value)}</Badge>
                    }
                },
            },
            {
                Header: 'Action',
                accessor: 'marketId',
                Cell: ({ value, row }) => {
                    return row.values.position !== 'CLOSED' ?
                        <Button variant="danger" size="sm" onClick={() => {
                            const id = toast.loading(format("Closing market..."))
                            closeUserMarket(value)
                                .then(() => {
                                    toast.success("Market successfully closed", { id })
                                    incrementRefresh()
                                })
                                .catch(() => toast.success("An error occured whioe closing market", { id }))
                        }}>Close</Button> :
                        <Button variant="success" size="sm" onClick={() => {
                            const id = toast.loading(format("Opening market..."))
                            openUserMarket(value)
                                .then(() => {
                                    toast.success("Market successfully opened", { id })
                                    incrementRefresh()
                                })
                                .catch(() => toast.success("An error occured whioe opening market", { id }))
                        }}>Open</Button>
                }
            }
        ],
        values: markets,
        initialState: {
            sortBy: [
                {
                    id: 'base',
                    desc: true
                }
            ],
            pageSize: 5,
        }
    }
    return data
}