import jwt_decode from "jwt-decode";
import { Token } from './Enums.ts'

export const getToken = (type) => {
    return window.localStorage.getItem(type)
}

export const setToken = (type, token) => {
    window.localStorage.setItem(type, token)
}

export const removeToken = (type) => {
    window.localStorage.removeItem(type)
}

export const isAccessTokenValid = () => {
    let token = getToken(Token.Access)
    if (token) {
        let decodedAccessToken = jwt_decode(token)

        let expirationDate = decodedAccessToken.exp * 1000

        if (expirationDate > new Date().getTime()) {
            return true;
        } else {
            return false
        }

    } else {
        return false;
    }
}